import React from "react";

export const Features = (props) => {

  
const data = props.data?.Why.concat(props.data.Why2);
  return (
    <div id="features">
      <div className="container text-center ">
        <div className="row">
          <div className=" section-title">
            <h2>Features</h2>
            <div className="row">
              {props.data
                ? props.data.list.map((d, i) => (
                    <div key={`${d.title}-${i}`} className="col-md-6">
                      <i className={d.icon}></i>
                      <h1 className="display-5 text-dark">{d?.title}</h1>

                      <h4 className="lead">{d.text}</h4>
                    </div>
                  ))
                : "Loading..."}
            </div>
            <h3 class="display-6">Why choose us?</h3>

            <div className="about-text">
              <h4 style={{ marginBottom: "3rem" }}>
                Introducing Plumbazar's Comprehensive Online Platform Features
              </h4>

              <div class="list">
                <ul>
                  {data && data.map((d, i) => (
                    <li style={{textAlign:"left"}}>
                      <span >{d}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
