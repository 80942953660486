import React from "react";

const data = [
  "Cashbacks and vouchers",
  "Special discounts",
  "Priority checkout and deliveries",
  "Free Market Access",
  "Revenue Generation programs",
  "Commission-Based Revenue Model",
  "Boosting Sales of Vendors and service providers",
  "Vendor Management &amp; Retention",
  "Market Development by driving progress for all stakeholders",
];

export const PartnerProgram = () => {
  return (
    <section id="partnerProgram" >
      <div className="container">
        <div className="section-title text-center mb-4">
          <h2 className="font-weight-bold">Plumbazar Partner Program</h2>
          <p className="text-muted mb-0">
            Plumbazar Partner Program for Contractors, Architects, Electricians
            & Plumbers
          </p>
        </div>

        <div class="list">
          <ul>
            {data.map((d, i) => (
              <li>
                <span>{d}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
