export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text ">
              <h2>About Us</h2>
              <p>
                Welcome to Plumbazar, your premier online marketplace dedicated
                to revolutionizing the procurement of construction materials and
                local services. Our platform seamlessly connects skilled service
                providers with service seekers, aiming to transform the way
                local professionals work and are compensated
              </p>
              <p>
                In the realm of home services, traditional approaches have seen
                limited innovation. Plumbazar disrupts this norm by directly
                linking users with top-tier professionals who excel in their
                respective fields. We work hand in hand with individual service
                providers, empowering them to become micro-entrepreneurs through
                a comprehensive package of benefits. This includes market
                access, training, insurance, and a plethora of other invaluable
                services. At Plumbazar, we provide jobseekers with leads by
                standardizing the end-user experience, pricing, and delivering
                services under the reputable Plumbazar brand.
              </p>
              <p>
                Our cutting-edge app-based platform expands exposure for service
                providers, enabling them to attract a broader customer base than
                they would through traditional methods alone. From wholesale
                plumbing and electrical goods to sanitary materials, our unique
                procurement platform offers a wide array of hardware products
                online. This not only streamlines the service material
                procurement process for buyers but also empowers retail vendors
                to efficiently manage their inventory by sourcing hardware goods
                from reputable wholesale vendors through our app and web-based
                platform. We facilitate essential procurement processes such as
                price discovery, quote generation, instant purchase, approval
                management, and order handling, providing a comprehensive
                solution.
              </p>
              <p>
                At Plumbazar, we drive significant cost and time savings in the
                material procurement process, marking a monumental disruption in
                the real estate, home services, and construction industry. We're
                breaking free from the paper-based and offline processes that
                have traditionally dominated this industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
