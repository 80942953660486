import logo from "./../assets/PLUMBAZAR.png";
export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container" style={{ padding: "0px" }}>
        <div
          className="navbar-header "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", marginRight: "20%" }}>
            <img src={logo} style={{ width: "70px" }} alt="plumbazar" />
            <a href="#" className="navbar-brand page-scroll">
              PlumBazar
            </a>
          </div>

          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <i class="fas fa-bars"></i>
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#top" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li>

            <li>
              <a href="#partnerProgram" className="page-scroll">
                PARTNER PROGRAM
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
